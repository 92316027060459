// TODO: Finalize this file for v1.1, so customers can define their own theme

// Theme
//
// Change the default theme color, fonts, sizing, etc. by modifying
// the following variables.

// Google fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Dosis:200,400,500,600');


//
// Color system
//
/*
$blue:                        #0facf3;
$indigo:                      #6610f2;
$purple:                      #6f42c1;
$pink:                        #e83e8c;
$red:                         #ff4954;
$orange:                      #ffbe00;
$yellow:                      #ffba00;
$green:                       #46da60;
$teal:                        #20c997;
$cyan:                        #17a2b8;

$color-primary:               $blue;
$color-secondary:             #e4e7ea;
$color-success:               $green;
$color-info:                  $cyan;
$color-warning:               $yellow;
$color-danger:                $red;
$color-light:                 #f8f9fa;
$color-dark:                  #343a40;


//
// Text colors
//
$color-title:                 #37404d;
$color-text:                  #535353;
$color-text-light:            #999999;
$color-text-secondary:        #b5b9bf;
$color-text-disable:          #a5b3c7;
$color-text-placeholder:      #b7bbbd;

//
// Background colors
//
$color-bg-lightest:           #fcfdfe;
$color-bg-lighter:            #f9fafb;
$color-bg-light:              #f5f6f7;
$color-bg-body:               #ffffff;
$color-bg-dark:               #191919;
$color-bg-gray:               #f9f9f9;
*/

//
// Fonts
//

$font-family-base:            "Open Sans", sans-serif;
$font-family-title:           Dosis, sans-serif;

$font-size-base:              0.9375rem;

$font-weight-base:            300;
$line-height-base:            1.9;

$h1-font-size:                $font-size-base * 2.75;
$h2-font-size:                $font-size-base * 2.25;
$h3-font-size:                $font-size-base * 1.875;
$h4-font-size:                $font-size-base * 1.625;
$h5-font-size:                $font-size-base * 1.3125;
$h6-font-size:                $font-size-base * 1.125;

$h1-font-weight:              400; //200;
$h2-font-weight:              400; //200;
$h3-font-weight:              400; //200;
$h4-font-weight:              400; //300;
$h5-font-weight:              400; //400;
$h6-font-weight:              400; //700;

//$headings-font-weight:        200;

$display1-size:               5rem;
$display2-size:               4rem;
$display3-size:               3.5rem;
$display4-size:               3rem;

$display1-weight:             200;
$display2-weight:             200;
$display3-weight:             200;
$display4-weight:             200;



// Options
//
// Quickly modify global styling by enabling or disabling optional features.


